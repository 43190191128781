import useFetch from "../../../utils/fetch-data";
import { useSessionStorage } from "../../../utils/local-storage";
import { PatientPracticeResponse } from "../../types/patient-practice.type";
import { useMemo } from "react";

const PRACTICE_INFO_API_PATH = "/patient-portal/practice/";

export function usePracticeData(practiceName: string, practiceId = "") {
  const localAuthSession = useSessionStorage("authToken");
  const params = useMemo(
    () =>
      practiceId
        ? {
            practice_id: Number.parseInt(practiceId),
          }
        : undefined,
    [practiceId],
  );
  const { isLoading, data, isError }: PatientPracticeResponse = useFetch({
    method: "GET",
    url: `${process.env.REACT_APP_SUNO_API}${PRACTICE_INFO_API_PATH}${practiceName}/`,
    params,
    token: localAuthSession.getData(null),
  });

  return {
    data,
    isLoading,
    isError,
  };
}
